import React, { FC } from "react";
import { Link } from "gatsby"

const h1Classes = `text-2xl m-2`

const NotFoundPage: FC = () => {
  return (
    <main className="text-primary font-gothic antialiased">
      <h1 className={h1Classes}>Page not found</h1>
      <p>
        Sorry 😔, we could not find what you were looking for.
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>